@import url("https://fonts.googleapis.com/css?family=Poppins:400,600");

// colors
$no-color: transparent;
$white: white;
$grey-primary: #d3d9e0;
$black-lighter: #4b4e61;
$blue-primary: #395ee5;
$blue-secondary: #3053d6;
$blue-lighter: #0091f9;
$blue-lighter-secondary: #6ac2fb;
$blue-lighter-third: #99d4fd;
$blue-lightest: #d7edff;
$red-primary: #fd4b51;

// sizes
$desktop: 1440px;
$tablet: 1220px;
$phone: 865px;
$min-width: 300px;

// fonts
$poppins: ("Poppins", sans-serif);
$poppins-semi-bold: ("Poppins Semi Bold", sans-serif);
