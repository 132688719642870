@import url(https://fonts.googleapis.com/css?family=Poppins:400,600);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600);
body {
  margin: 0 auto;
  padding: 0;
  font-family: "Poppins", sans-serif;
  min-width: 300px;
  background-color: #395ee5; }
  @media (min-width: 1440px) {
    body {
      width: 1440px; } }

* {
  box-sizing: border-box; }
  *:focus {
    outline: none !important;
    box-shadow: none !important; }

body {
  position: relative;
  min-height: 100vh; }

.App {
  padding-bottom: 200px; }

.style_buttonWrapper__1AcsU button {
  height: 48px;
  padding: 0px 29px;
  color: white;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  border: none;
  background: none; }
  @media (max-width: 865px) {
    .style_buttonWrapper__1AcsU button {
      font-size: 13px;
      height: 32px;
      padding: 0 10px; } }
  .style_buttonWrapper__1AcsU button:hover {
    cursor: pointer; }
  .style_buttonWrapper__1AcsU button.style_active__2IFup {
    box-shadow: 0 23px 21px -12px rgba(0, 0, 0, 0.18) !important;
    border-radius: 52px;
    background-image: -webkit-linear-gradient(315deg, #00a2ff 0%, #5560f3 100%);
    background-image: linear-gradient(135deg, #00a2ff 0%, #5560f3 100%); }

.style_header__2fyjW {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 38px 41px 0px 53px; }
  @media (max-width: 865px) {
    .style_header__2fyjW {
      padding: 20px 10px 20px 10px; } }
  .style_header__2fyjW .style_logo__1t2zj {
    display: flex;
    align-items: center; }
    .style_header__2fyjW .style_logo__1t2zj:hover {
      cursor: pointer; }
    @media (max-width: 865px) {
      .style_header__2fyjW .style_logo__1t2zj .style_imgLogo__2YEYm {
        width: 50px; } }
    .style_header__2fyjW .style_logo__1t2zj span {
      color: white;
      font-size: 22px;
      margin-left: 8px; }
      @media (max-width: 865px) {
        .style_header__2fyjW .style_logo__1t2zj span {
          font-size: 18px; } }
  .style_header__2fyjW .style_links__2DDZs {
    display: flex;
    align-items: center; }

.style_footer__1xgw0 .style_inner__1LBR_ {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 16px; }
  .style_footer__1xgw0 .style_inner__1LBR_ .style_visited__2EHzJ {
    width: 406px;
    padding: 20px 0px;
    background-color: white;
    margin-bottom: 12px;
    margin-left: 12px; }
    @media (max-width: 1220px) {
      .style_footer__1xgw0 .style_inner__1LBR_ .style_visited__2EHzJ {
        width: 50%;
        margin-top: 20px;
        margin: 20px auto 12px; } }
    @media (max-width: 865px) {
      .style_footer__1xgw0 .style_inner__1LBR_ .style_visited__2EHzJ {
        width: 90%;
        margin: 20px auto; } }
    .style_footer__1xgw0 .style_inner__1LBR_ .style_visited__2EHzJ .style_rating__2RNFa {
      display: flex;
      align-items: center;
      padding-left: 20px; }
      .style_footer__1xgw0 .style_inner__1LBR_ .style_visited__2EHzJ .style_rating__2RNFa .style_circleMark__2aVud {
        width: 60px;
        height: 60px;
        background-color: #d7edff;
        border-radius: 50%;
        padding: 8px; }
        @media (max-width: 865px) {
          .style_footer__1xgw0 .style_inner__1LBR_ .style_visited__2EHzJ .style_rating__2RNFa .style_circleMark__2aVud {
            width: 40px;
            height: 40px;
            padding: 4px; } }
        .style_footer__1xgw0 .style_inner__1LBR_ .style_visited__2EHzJ .style_rating__2RNFa .style_circleMark__2aVud span {
          width: 42px;
          height: 42px;
          background-color: #0091f9;
          border-radius: 50%;
          display: inline-block; }
          @media (max-width: 865px) {
            .style_footer__1xgw0 .style_inner__1LBR_ .style_visited__2EHzJ .style_rating__2RNFa .style_circleMark__2aVud span {
              width: 32px;
              height: 32px; } }
      .style_footer__1xgw0 .style_inner__1LBR_ .style_visited__2EHzJ .style_rating__2RNFa .style_desc__38Eeu {
        margin-left: 15px; }
        .style_footer__1xgw0 .style_inner__1LBR_ .style_visited__2EHzJ .style_rating__2RNFa .style_desc__38Eeu .style_title__zJfGg {
          padding-left: 6px; }
          .style_footer__1xgw0 .style_inner__1LBR_ .style_visited__2EHzJ .style_rating__2RNFa .style_desc__38Eeu .style_title__zJfGg span {
            color: #4b4e61;
            font-size: 16px; }
            @media (max-width: 865px) {
              .style_footer__1xgw0 .style_inner__1LBR_ .style_visited__2EHzJ .style_rating__2RNFa .style_desc__38Eeu .style_title__zJfGg span {
                font-size: 12px; } }
            .style_footer__1xgw0 .style_inner__1LBR_ .style_visited__2EHzJ .style_rating__2RNFa .style_desc__38Eeu .style_title__zJfGg span strong {
              color: #6ac2fb; }
        .style_footer__1xgw0 .style_inner__1LBR_ .style_visited__2EHzJ .style_rating__2RNFa .style_desc__38Eeu .style_verified__1tQst {
          display: flex;
          align-items: center; }
          .style_footer__1xgw0 .style_inner__1LBR_ .style_visited__2EHzJ .style_rating__2RNFa .style_desc__38Eeu .style_verified__1tQst .style_verifiedImage__3iYZ9 {
            width: 16px;
            height: 16px;
            display: inline-block;
            position: relative;
            background-color: #99d4fd;
            border-radius: 50%; }
            .style_footer__1xgw0 .style_inner__1LBR_ .style_visited__2EHzJ .style_rating__2RNFa .style_desc__38Eeu .style_verified__1tQst .style_verifiedImage__3iYZ9 img {
              width: 9px;
              height: 8px;
              position: absolute;
              left: 50%;
              right: 50%;
              -webkit-transform: translate(-50%, 58%);
                      transform: translate(-50%, 58%); }
          .style_footer__1xgw0 .style_inner__1LBR_ .style_visited__2EHzJ .style_rating__2RNFa .style_desc__38Eeu .style_verified__1tQst .style_verifiedLabel__125fm {
            margin-left: 6px;
            color: #d3d9e0;
            font-size: 15px; }
            @media (max-width: 865px) {
              .style_footer__1xgw0 .style_inner__1LBR_ .style_visited__2EHzJ .style_rating__2RNFa .style_desc__38Eeu .style_verified__1tQst .style_verifiedLabel__125fm {
                font-size: 12px; } }
  .style_footer__1xgw0 .style_inner__1LBR_ .style_copyright__2s1Zc {
    text-align: center; }
    .style_footer__1xgw0 .style_inner__1LBR_ .style_copyright__2s1Zc p {
      color: white; }
      @media (max-width: 1220px) {
        .style_footer__1xgw0 .style_inner__1LBR_ .style_copyright__2s1Zc p {
          padding: 0px 34px; } }
      @media (max-width: 865px) {
        .style_footer__1xgw0 .style_inner__1LBR_ .style_copyright__2s1Zc p {
          font-size: 12px; } }

.style_player__F_wHM {
  display: flex;
  align-items: center;
  height: 32px;
  padding-right: 20px;
  border-radius: 16px;
  background-color: #3053d6;
  -webkit-animation-name: style_hovering__ikMSW;
          animation-name: style_hovering__ikMSW;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate; }

@-webkit-keyframes style_hovering__ikMSW {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  100% {
    -webkit-transform: translate3d(0, -4px, 0);
            transform: translate3d(0, -4px, 0); } }

@keyframes style_hovering__ikMSW {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  100% {
    -webkit-transform: translate3d(0, -4px, 0);
            transform: translate3d(0, -4px, 0); } }
  .style_player__F_wHM .style_button__1fhPN {
    width: 32px;
    height: 32px;
    background-color: white;
    border-radius: 50%;
    padding: 5px 11px; }
    @media (max-width: 865px) {
      .style_player__F_wHM .style_button__1fhPN {
        width: 20px;
        height: 20px;
        padding: 0px; } }
    .style_player__F_wHM .style_button__1fhPN:hover {
      cursor: pointer;
      background-color: #ededed; }
  .style_player__F_wHM .style_title__2m9h6 {
    margin-left: 10px; }
    .style_player__F_wHM .style_title__2m9h6 span {
      color: white;
      font-family: "Poppins Semi Bold", sans-serif;
      font-size: 15px;
      font-weight: 600; }
      @media (max-width: 865px) {
        .style_player__F_wHM .style_title__2m9h6 span {
          font-size: 12px; } }
  .style_player__F_wHM .style_status__3hX94 {
    margin-left: 11px; }
    .style_player__F_wHM .style_status__3hX94 .style_statusImg__3DFBS {
      width: 7px;
      height: 7px;
      background-color: #fd4b51;
      border-radius: 50%;
      display: inline-block;
      vertical-align: middle; }
    .style_player__F_wHM .style_status__3hX94 .style_statusLabel__2Vl9P {
      color: white;
      font-family: "Poppins Semi Bold", sans-serif;
      font-size: 9px;
      font-weight: 600;
      text-transform: uppercase;
      margin-left: 4px;
      display: inline-block;
      vertical-align: middle; }

.style_home__2r1im .style_content__3AYI4 {
  padding-top: 162px;
  text-align: center; }
  @media (max-width: 865px) {
    .style_home__2r1im .style_content__3AYI4 {
      padding-top: 55px; } }
  .style_home__2r1im .style_content__3AYI4 .style_ads__370vQ {
    position: relative;
    width: 1200px;
    height: 341px;
    margin: 0 auto;
    background-size: cover;
    background-position: -5px 32px;
    background-repeat: no-repeat;
    background-image: url(/static/media/map_bg.72e1ebe6.png); }
    @media (max-width: 1220px) {
      .style_home__2r1im .style_content__3AYI4 .style_ads__370vQ {
        width: 850px;
        background-size: 151%;
        background-position: bottom; } }
    @media (max-width: 865px) {
      .style_home__2r1im .style_content__3AYI4 .style_ads__370vQ {
        width: auto;
        height: 210px;
        background-position: center; } }
    .style_home__2r1im .style_content__3AYI4 .style_ads__370vQ .style_bright__3TUYd {
      width: 417px;
      height: 417px;
      background-image: url(/static/media/oval.dbacf2f6.png);
      border-radius: 50%;
      opacity: 0.42;
      position: absolute;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      z-index: 1; }
      @media (max-width: 865px) {
        .style_home__2r1im .style_content__3AYI4 .style_ads__370vQ .style_bright__3TUYd {
          width: 250px;
          height: 250px; } }
    .style_home__2r1im .style_content__3AYI4 .style_ads__370vQ > img {
      position: absolute;
      left: 50%;
      -webkit-transform: translate(-50%, 0);
              transform: translate(-50%, 0);
      z-index: 2; }
      @media (max-width: 865px) {
        .style_home__2r1im .style_content__3AYI4 .style_ads__370vQ > img {
          width: 330px; } }
    .style_home__2r1im .style_content__3AYI4 .style_ads__370vQ .style_playerHenry__2iC2Q {
      position: absolute;
      left: 471px;
      top: -61px;
      z-index: 3; }
      @media (max-width: 1220px) {
        .style_home__2r1im .style_content__3AYI4 .style_ads__370vQ .style_playerHenry__2iC2Q {
          left: 301px; } }
      @media (max-width: 865px) {
        .style_home__2r1im .style_content__3AYI4 .style_ads__370vQ .style_playerHenry__2iC2Q {
          left: 45%; } }
    .style_home__2r1im .style_content__3AYI4 .style_ads__370vQ .style_playerMom__14Csn {
      position: absolute;
      left: 645px;
      top: -11px;
      z-index: 3; }
      @media (max-width: 1220px) {
        .style_home__2r1im .style_content__3AYI4 .style_ads__370vQ .style_playerMom__14Csn {
          left: 489px; } }
      @media (max-width: 865px) {
        .style_home__2r1im .style_content__3AYI4 .style_ads__370vQ .style_playerMom__14Csn {
          left: 60%; } }
    .style_home__2r1im .style_content__3AYI4 .style_ads__370vQ .style_playerDad__3Lsu9 {
      position: absolute;
      left: 585px;
      top: 68px;
      z-index: 3; }
      @media (max-width: 1220px) {
        .style_home__2r1im .style_content__3AYI4 .style_ads__370vQ .style_playerDad__3Lsu9 {
          left: 415px; } }
      @media (max-width: 865px) {
        .style_home__2r1im .style_content__3AYI4 .style_ads__370vQ .style_playerDad__3Lsu9 {
          left: 57%; } }
  .style_home__2r1im .style_content__3AYI4 .style_desc__1LWZV {
    width: 883px;
    margin: 0 auto; }
    @media (max-width: 1220px) {
      .style_home__2r1im .style_content__3AYI4 .style_desc__1LWZV {
        width: 505px; } }
    @media (max-width: 865px) {
      .style_home__2r1im .style_content__3AYI4 .style_desc__1LWZV {
        width: auto;
        padding: 0px 20px; } }
    .style_home__2r1im .style_content__3AYI4 .style_desc__1LWZV h1 {
      color: white;
      font-family: "Poppins", sans-serif;
      font-size: 40px;
      margin: 0px;
      font-weight: 100; }
      @media (max-width: 1220px) {
        .style_home__2r1im .style_content__3AYI4 .style_desc__1LWZV h1 {
          font-size: 25px; } }
      @media (max-width: 865px) {
        .style_home__2r1im .style_content__3AYI4 .style_desc__1LWZV h1 {
          font-size: 15px; } }
    .style_home__2r1im .style_content__3AYI4 .style_desc__1LWZV p {
      opacity: 0.67;
      color: white;
      font-family: "Poppins", sans-serif;
      font-size: 18px;
      font-weight: 400; }
      @media (max-width: 1220px) {
        .style_home__2r1im .style_content__3AYI4 .style_desc__1LWZV p {
          font-size: 14px; } }
  .style_home__2r1im .style_content__3AYI4 .style_create__p8ZFS {
    width: 280px;
    padding-bottom: 27px;
    border-radius: 11px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    display: flex;
    flex-direction: column;
    margin: 30px auto 0px; }
    .style_home__2r1im .style_content__3AYI4 .style_create__p8ZFS .style_title__30zJh h3 {
      color: white;
      font-family: "Poppins Semi Bold", sans-serif;
      font-size: 16px;
      font-weight: 600; }
    .style_home__2r1im .style_content__3AYI4 .style_create__p8ZFS .style_buttons__3iG3y {
      display: flex;
      justify-content: center; }
      .style_home__2r1im .style_content__3AYI4 .style_create__p8ZFS .style_buttons__3iG3y > div.style_facebook__30fPj {
        padding: 10px 10px 3px;
        background-color: white;
        border-radius: 17px; }
        .style_home__2r1im .style_content__3AYI4 .style_create__p8ZFS .style_buttons__3iG3y > div.style_facebook__30fPj img {
          width: 46px;
          height: 47px; }
      .style_home__2r1im .style_content__3AYI4 .style_create__p8ZFS .style_buttons__3iG3y > div.style_google__1LrAI {
        padding: 12px 10px 7px;
        border-radius: 17px;
        background-color: white;
        margin-left: 15px; }
        .style_home__2r1im .style_content__3AYI4 .style_create__p8ZFS .style_buttons__3iG3y > div.style_google__1LrAI img {
          width: 47px;
          height: 41px; }
      .style_home__2r1im .style_content__3AYI4 .style_create__p8ZFS .style_buttons__3iG3y > div:hover {
        cursor: pointer;
        background-color: #ededed; }

.style_home__2r1im .style_footer__1ZAkD .style_visited__3gkf_ {
  width: 406px;
  padding: 20px 0px;
  background-color: white;
  margin-bottom: 12px;
  margin-left: 12px; }
  @media (max-width: 1220px) {
    .style_home__2r1im .style_footer__1ZAkD .style_visited__3gkf_ {
      width: 50%;
      margin-top: 20px;
      margin: 20px auto 12px; } }
  @media (max-width: 865px) {
    .style_home__2r1im .style_footer__1ZAkD .style_visited__3gkf_ {
      width: 90%;
      margin: 20px auto; } }
  .style_home__2r1im .style_footer__1ZAkD .style_visited__3gkf_ .style_rating__2CDvh {
    display: flex;
    align-items: center;
    padding-left: 20px; }
    .style_home__2r1im .style_footer__1ZAkD .style_visited__3gkf_ .style_rating__2CDvh .style_circleMark__bLB77 {
      width: 60px;
      height: 60px;
      background-color: #d7edff;
      border-radius: 50%;
      padding: 8px; }
      @media (max-width: 865px) {
        .style_home__2r1im .style_footer__1ZAkD .style_visited__3gkf_ .style_rating__2CDvh .style_circleMark__bLB77 {
          width: 40px;
          height: 40px;
          padding: 4px; } }
      .style_home__2r1im .style_footer__1ZAkD .style_visited__3gkf_ .style_rating__2CDvh .style_circleMark__bLB77 span {
        width: 42px;
        height: 42px;
        background-color: #0091f9;
        border-radius: 50%;
        display: inline-block; }
        @media (max-width: 865px) {
          .style_home__2r1im .style_footer__1ZAkD .style_visited__3gkf_ .style_rating__2CDvh .style_circleMark__bLB77 span {
            width: 32px;
            height: 32px; } }
    .style_home__2r1im .style_footer__1ZAkD .style_visited__3gkf_ .style_rating__2CDvh .style_desc__1LWZV {
      margin-left: 15px; }
      .style_home__2r1im .style_footer__1ZAkD .style_visited__3gkf_ .style_rating__2CDvh .style_desc__1LWZV .style_title__30zJh {
        padding-left: 6px; }
        .style_home__2r1im .style_footer__1ZAkD .style_visited__3gkf_ .style_rating__2CDvh .style_desc__1LWZV .style_title__30zJh span {
          color: #4b4e61;
          font-size: 16px; }
          @media (max-width: 865px) {
            .style_home__2r1im .style_footer__1ZAkD .style_visited__3gkf_ .style_rating__2CDvh .style_desc__1LWZV .style_title__30zJh span {
              font-size: 12px; } }
          .style_home__2r1im .style_footer__1ZAkD .style_visited__3gkf_ .style_rating__2CDvh .style_desc__1LWZV .style_title__30zJh span strong {
            color: #6ac2fb; }
      .style_home__2r1im .style_footer__1ZAkD .style_visited__3gkf_ .style_rating__2CDvh .style_desc__1LWZV .style_verified__fAgl0 {
        display: flex;
        align-items: center; }
        .style_home__2r1im .style_footer__1ZAkD .style_visited__3gkf_ .style_rating__2CDvh .style_desc__1LWZV .style_verified__fAgl0 .style_verifiedImage__1gitI {
          width: 16px;
          height: 16px;
          display: inline-block;
          position: relative;
          background-color: #99d4fd;
          border-radius: 50%; }
          .style_home__2r1im .style_footer__1ZAkD .style_visited__3gkf_ .style_rating__2CDvh .style_desc__1LWZV .style_verified__fAgl0 .style_verifiedImage__1gitI img {
            width: 9px;
            height: 8px;
            position: absolute;
            left: 50%;
            right: 50%;
            -webkit-transform: translate(-50%, 58%);
                    transform: translate(-50%, 58%); }
        .style_home__2r1im .style_footer__1ZAkD .style_visited__3gkf_ .style_rating__2CDvh .style_desc__1LWZV .style_verified__fAgl0 .style_verifiedLabel__3N0qT {
          margin-left: 6px;
          color: #d3d9e0;
          font-size: 15px; }
          @media (max-width: 865px) {
            .style_home__2r1im .style_footer__1ZAkD .style_visited__3gkf_ .style_rating__2CDvh .style_desc__1LWZV .style_verified__fAgl0 .style_verifiedLabel__3N0qT {
              font-size: 12px; } }

.style_home__2r1im .style_footer__1ZAkD .style_copyright__ov5U- {
  text-align: center; }
  .style_home__2r1im .style_footer__1ZAkD .style_copyright__ov5U- p {
    color: white; }
    @media (max-width: 1220px) {
      .style_home__2r1im .style_footer__1ZAkD .style_copyright__ov5U- p {
        padding: 0px 34px; } }
    @media (max-width: 865px) {
      .style_home__2r1im .style_footer__1ZAkD .style_copyright__ov5U- p {
        font-size: 12px; } }

.style_contact__2yVoW .style_content__2B_ka {
  padding-top: 162px;
  text-align: center; }
  @media (max-width: 865px) {
    .style_contact__2yVoW .style_content__2B_ka {
      padding-top: 55px; } }
  .style_contact__2yVoW .style_content__2B_ka h1 {
    color: white;
    font-family: "Poppins", sans-serif;
    font-size: 40px;
    margin: 0px;
    font-weight: 100; }
    @media (max-width: 1220px) {
      .style_contact__2yVoW .style_content__2B_ka h1 {
        font-size: 32px; } }
    @media (max-width: 865px) {
      .style_contact__2yVoW .style_content__2B_ka h1 {
        font-size: 24px; } }
  .style_contact__2yVoW .style_content__2B_ka a {
    color: white; }
  .style_contact__2yVoW .style_content__2B_ka p {
    text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
    font-size: 24px;
    color: white; }
    @media (max-width: 1220px) {
      .style_contact__2yVoW .style_content__2B_ka p {
        font-size: 18px; } }
    @media (max-width: 865px) {
      .style_contact__2yVoW .style_content__2B_ka p {
        font-size: 16px; } }

.style_contact__2yVoW .style_mapouter__1q7Su {
  position: relative;
  text-align: right;
  width: 100%;
  height: 33vh;
  min-height: 500px;
  margin: auto;
  border-radius: 4px;
  overflow: hidden;
  padding-top: 32px; }

.style_contact__2yVoW .style_map__2Ge4E {
  width: 100%;
  height: 100%; }

