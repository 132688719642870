@import "../../assets/scss/base.scss";

.contact {
  .content {
    padding-top: 162px;
    text-align: center;

    @include phone {
      padding-top: 55px;
    }

    h1 {
      color: $white;
      font-family: $poppins;
      font-size: 40px;
      margin: 0px;
      font-weight: 100;

      @include tablet {
        font-size: 32px;
      }
      @include phone {
        font-size: 24px;
      }
    }

    a {
      color: white;
    }

    p {
      text-shadow: 1px 1px rgba(0,0,0,.5);
      font-size: 24px;
      color: white;

      @include tablet {
        font-size: 18px;
      }
      @include phone {
        font-size: 16px;
      }
    }
  }

  .mapouter {
    position: relative;
    text-align: right;
    width: 100%;
    height: 33vh;
    min-height: 500px;

    margin: auto;
    border-radius: 4px;
    overflow: hidden;
    padding-top: 32px;
  }

  .map {
    width: 100%;
    height: 100%;
  }
}
